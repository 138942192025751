.background {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.73);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  .container {
    width: 400px;
    height: 250px;
    background: white;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;

    .header {
      width: 100%;
      height: 60px;
      background: var(--signature-color);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        height: 70%;
      }
    }

    // header

    p {
      text-align: center;
      width: 100%;
    }

    .warning {
      font-size: 32px;
      font-weight: 600;
      margin-top: 22px;
    }

    .date {
      font-size: 18px;
      margin-top: 16px;
      font-weight: 500;
    }

    .text {
      font-size: 18px;
      margin-top: 6px;
      font-weight: 500;
      margin-bottom: 18px;

      span {
        color: var(--lotte-red);
      }
    }
  }
}