
.container {
  width: 48%;
  border: 2px solid #e4e4e4;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;


  .label-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    .label {
      width: 80px;
      font-size: 14px;
      padding: 6px 0;
      background: #e6e6e6;
      text-align: center;
      border-radius: 4px;

      &.status {
        background: #918ddf;
        color: white;
      }
    }
  }

  .pump-status {
    width: 31%;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;

    .pump-name {
      display: inline-block;
      padding: 3px 8px;
      border: 2px solid #8a8a8a;
      border-radius: 8px;
      color: #8a8a8a;
    }

    .pump-icon {
      height: 100px;
      margin: 8px 24px 10px 0;
    }

    .pump-power {
      background: #bdbdbd;
      padding: 6px 20px;
      border-radius: 1000px;
      color: #fff;

      &.on {
        background: var(--signature-color);
      }


    }

    @keyframes blink {
      0% {
        filter: drop-shadow(0 0 3px red) drop-shadow(0 0 3px red);
      }
      50% {
        filter: none;
      }
      100% {
        filter: drop-shadow(0 0 3px red) drop-shadow(0 0 3px red);
      }
    }

    .tank-icon {
      width: 80%;

      &.tank-warning {
        animation: blink 3s infinite;
      }
    }

    .tank-status {
      width: 80%;
      height: 140px;
      margin: 8px 0 10px;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: repeat(3, 1fr);

      & > * {
        border-bottom: 1px solid #ececec;
        background: #dcdcdc;
        display: flex;
        align-items: center;
        position: relative;

        .level-text {
          font-weight: 600;
          font-size: 18px;
          flex-grow: 1;
          text-align: center;
        }

        .warning {
          color: var(--lotte-red);
        }

        .warning-icon {
          width: 24px;
          position: absolute;
          left: 12px;
          font-size: 20px;
          color: #ffbc13;
        }


        &.active {
          background: cornflowerblue;
        }
      }
    }

    // tank-status
  }

  // pump-status
}

.pop-container {
  color: #3e3e3e;
  font-size: 12px;

  &.none {
    display: none;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;

  .warning-icon {
    width: 18px;
    margin-right: 6px;
  }

  .text {
    display: inline-block;
    font-size: 14px;
  }

  .alarm-name {
    color: var(--lotte-red);
  }
}