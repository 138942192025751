.title-container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;

  .sync-time {
    font-size: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .sync-icon {
      font-size: 18px;
      margin-left: 5px;
      cursor: pointer;
      color: #393939;

      &:hover {
        color: var(--signature-color);
      }
    }
  }
}

.filter-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  .button-container {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    margin-left: auto;
  }
}

.pump-status-container {
  width: 100%;
  background: white;
  margin-top: 18px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  .title {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  .empty {
    margin: auto;
  }
}

@keyframes blink {
  0% {
    background-color: #ffeeee;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #ffeeee;
  }
}

.table-container {
  width: 100%;
  margin-top: 18px;
  background: white;
  display: flex;
  flex-wrap: wrap;

  .title {
    padding: 24px 24px 0;
    box-sizing: border-box;
  }


  .unchecked {
    animation: blink 1s infinite;
  }
}


